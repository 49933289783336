export default {
  orderList: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'BatchDel',
      title: '批量删除'
    },
    {
      name: name + 'ConfirmDraw',
      title: '确认图纸'
    },
    {
      name: name + 'ConfirmBom',
      title: '确认BOM'
    },
    {
      name: name + 'Import',
      title: '导入'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ],
  orderAdd: (name) => [
    {
      name: name + 'Add',
      title: '新增'
    }
  ],
  orderDetail: (name) => [
    {
      name: name + 'List',
      title: '查看详情'
    },
    {
      name: name + 'AddWorkOrder',
      title: '关联工单'
    },
    {
      name: name + 'CancelWorkOrder',
      title: '取消工单'
    },
    {
      name: name + 'AddPlanOrder',
      title: '关联计划'
    },
    {
      name: name + 'CancelPlanOrder',
      title: '取消计划'
    }
  ],
  planAdd: (name) => [
    {
      name: name + 'List',
      title: '查看计划'
    },
    {
      name: name + 'CreatePlan',
      title: '创建计划'
    }
  ],
  planList: (name) => [
    {
      name: name + 'ANoScheduleList',
      title: '未排程',
      children: [
        {
          name: name + 'NoScheduleList',
          title: '列表'
        },
        {
          name: name + 'NoScheduleModify',
          title: '修改'
        },
        {
          name: name + 'NoScheduleDel',
          title: '删除'
        },
        {
          name: name + 'NoScheduleDownWorkOrder',
          title: '下发至工单'
        },
        {
          name: name + 'NoScheduleExport',
          title: '导出'
        },
        {
          name: name + 'NoScheduleStock',
          title: '备料'
        }
      ]
    },
    {
      name: name + 'BHaveScheduleList',
      title: '已排程',
      children: [
        {
          name: name + 'HaveScheduleList',
          title: '列表'
        },
        {
          name: name + 'HaveScheduleDownWorkOrder',
          title: '下发至工单'
        },
        {
          name: name + 'HaveScheduleModify',
          title: '修改'
        },
        {
          name: name + 'HaveScheduleDel',
          title: '删除'
        },
        {
          name: name + 'HaveScheduleExport',
          title: '导出'
        },
        {
          name: name + 'HaveScheduleStock',
          title: '备料'
        }
      ]
    }
  ],
  prodPlanList: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'StartWork',
      title: '开工'
    },
    {
      name: name + 'Over',
      title: '结案'
    },
    {
      name: name + 'Edit',
      title: '编辑'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    // {
    //   name: name + 'Assembly',
    //   title: '装配完工'
    // },
    {
      name: name + 'Restart',
      title: '重启'
    },
    {
      name: name + 'Export',
      title: '导出'
    },
    {
      name: name + 'CreateProdPlan',
      title: '新建生产工单'
    },
    {
      name: name + 'PrintSequenceNumber',
      title: '打印序列码'
    },
    {
      name: name + 'AdjustStation',
      title: '工位位置调整'
    },
    {
      name: name + 'Detail',
      title: '工单详情'
    }
  ],
  prodPlanCreate: (name) => {
    if (sessionStorage.getItem('memberCode') === 'nozoli') {
      return [
        {
          name: name + 'gxwl',
          title: '工序物料'
        },
        {
          name: name + 'gdwl',
          title: '工单物料'
        },
        {
          name: name + 'gdqt',
          title: '工单齐套'
        },
        {
          name: name + 'InputMaterials',
          title: '投料记录'
        },
      ]
    } else {
      return [
        {
          name: name + 'gxwl',
          title: '工序物料'
        },
        {
          name: name + 'gdwl',
          title: '工单物料'
        },
        {
          name: name + 'InputMaterials',
          title: '投料记录'
        },
        {
          name: name + 'BatchEditProductionTask',
          title: '批量编辑生产任务'
        },
        {
          name: name + 'StartProductionTask',
          title: '开始生产任务'
        },
        {
          name: name + 'RestartProductionTask',
          title: '重启生产任务'
        },
        {
          name: name + 'CompleteWorkProductionTask',
          title: '完工生产任务'
        },
        {
          name: name + 'DelayFeedback',
          title: '延期反馈'
        }
      ]
    }
  },
  // 自制任务
  SelfmadeTask: name => [
    {
      name: name + 'OperatorTaskList',
      title: '列表'
    },
    {
      name: name + 'OperatorTaskDownStation',
      title: '下发至工位'
    },
    {
      name: name + 'OperatorTaskAssignTask',
      title: '指派任务'
    },
    {
      name: name + 'OutSource',
      title: '外协申请'
    },
    {
      name: name + 'OperatorTaskExport',
      title: '导出'
    }
  ],
  // 外协任务
  OutsourcingTask: name => [
    {
      name: name + 'OutSourceTaskList',
      title: '列表'
    },
    {
      name: name + 'OutSourceTaskAssignTask',
      title: '指派任务'
    },
    {
      name: name + 'OutSourceTaskExport',
      title: '导出'
    }
  ],
  workList: (name = 'workList') => {
    const base = [
      {
        name: name + 'AWorkOrderList',
        title: '生产工单',
        children: [
          {
            name: name + 'WorkOrderList',
            title: '列表'
          },
          {
            name: name + 'WorkOrderDel',
            title: '删除'
          },
          {
            name: name + 'Export',
            title: '导出'
          },
          {
            name: name + 'BatchPrint',
            title: '批量打印'
          }
        ]
      }
    ]

    if (sessionStorage.getItem('memberCode') === 'bbelc') {
      base.push(
        {
          name: name + 'BOperatorTaskList',
          title: '工序任务',
          children: [
            {
              name: name + 'OperatorTaskList',
              title: '列表'
            },
            {
              name: name + 'OperatorTaskDownStation',
              title: '下发至工位'
            },
            {
              name: name + 'OperatorTaskAssignTask',
              title: '指派任务'
            },
            {
              name: name + 'OutSource',
              title: '外协申请'
            },
            {
              name: name + 'OperatorTaskExport',
              title: '导出'
            }
          ]
        }
      )
    }
    //自制任务和外协任务摘出去了  不需要再通过memberCode去控制了  可以通过菜单名称/路由权限去控制了
    // else {
    //   base.push(
    //     {
    //       name: name + 'BOperatorTaskList',
    //       title: '自制任务',
    //       children: [
    //         {
    //           name: name + 'OperatorTaskList',
    //           title: '列表'
    //         },
    //         {
    //           name: name + 'OperatorTaskDownStation',
    //           title: '下发至工位'
    //         },
    //         {
    //           name: name + 'OperatorTaskAssignTask',
    //           title: '指派任务'
    //         },
    //         {
    //           name: name + 'OutSource',
    //           title: '外协申请'
    //         },
    //         {
    //           name: name + 'OperatorTaskExport',
    //           title: '导出'
    //         }
    //       ]
    //     },
    //     {
    //       name: name + 'COutSourceTaskList',
    //       title: '外协任务',
    //       children: [
    //         {
    //           name: name + 'OutSourceTaskList',
    //           title: '列表'
    //         },
    //         {
    //           name: name + 'OutSourceTaskAssignTask',
    //           title: '指派任务'
    //         },
    //         {
    //           name: name + 'OutSourceTaskExport',
    //           title: '导出'
    //         }
    //       ]
    //     }
    //   )
    // }
    return base
  },
  workDetail: (name = 'workDetail') => {
    const base = [
      {
        name: name + 'List',
        title: '查看工单详情'
      },
      {
        name: name + 'outSource',
        title: '外协'
      },
      {
        name: name + 'GoRepair',
        title: '发起返修'
      },
      {
        name: name + 'PrintWorkOrder',
        title: '打印'
      },
      {
        name: name + 'DelWorkOrder',
        title: '删除'
      },
      {
        name: name + 'AFirstList',
        title: '批次信息',
        children: [
          {
            name: name + 'firstList',
            title: '列表'
          },
          {
            name: name + 'firstAddBatchNumber',
            title: '添加批次'
          },
          {
            name: name + 'firstPrintCode',
            title: '打印批次码'
          },
          {
            name: name + 'firstDel',
            title: '删除'
          },
          {
            name: name + 'firstBatchOperate',
            title: '批量操作'
          }
        ]
      },
      {
        name: name + 'CPlansList',
        title: '计划用料',
        children: [
          {
            name: name + 'plansList',
            title: '列表'
          },
          {
            name: name + 'plansModifyMaterial',
            title: '编辑'
          }
        ]
      },
      {
        name: name + 'secondList',
        title: '投产记录'
      },
      {
        name: name + 'BThirdList',
        title: '报工记录',
        children: [
          {
            name: name + 'thirdList',
            title: '列表'
          },
          {
            name: name + 'thirdSubmitRecord',
            title: '查看报工记录'
          },
          {
            name: name + 'thirdScrapHistory',
            title: '查看报废历史'
          }
        ]
      },
      {
        name: name + 'fourList',
        title: '返修记录'
      }
    ]
    if (sessionStorage.getItem('memberCode') === 'mubai' ||
      sessionStorage.getItem('memberCode') === 'reagold' ||
      sessionStorage.getItem('memberCode') === 'kiki' ||
      sessionStorage.getItem('memberCode') === 'qingchu') {
      base.push(
        {
          name: name + 'reductionRecord',
          title: '减产'
        },
        {
          name: name + 'divisionRecord',
          title: '拆单'
        },
        {
          name: name + 'fiveList',
          title: '质检记录'
        },
        {
          name: name + 'sixList',
          title: '减产记录'
        },
        {
          name: name + 'sevenList',
          title: '拆单记录'
        }
      )
    }
    return base
  },
  reportRecords: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Export',
      title: '导出'
    },
    {
      name: name + 'BatchExport',
      title: '批量导出'
    }
  ],
  outSourceLists: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'BatchCheck',
      title: '批量通过'
    },
    {
      name: name + 'BatchCancel',
      title: '批量驳回'
    },
    {
      name: name + 'Export',
      title: '导出'
    },
    {
      name: name + 'Cancel',
      title: '撤销'
    }
  ],
  completeSet: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Detail',
      title: '查看'
    },
    {
      name: name + 'Add',
      title: '新建'
    },
    {
      name: name + 'Modify',
      title: '重新计算'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ],
  salesOrder: (name) => [
    {
      name: name + 'End',
      title: '完结'
    },
    {
      name: name + 'Edit',
      title: '修改'
    },
    {
      name: name + 'Cancellation',
      title: '作废'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'Restart',
      title: '重启'
    },
    {
      name: name + 'Detail',
      title: '详情'
    },
    {
      name: name + 'Add',
      title: '新增'
    }
  ],
  reserveStockOrder: (name) => [
    {
      name: name + 'End',
      title: '完结'
    },
    {
      name: name + 'Edit',
      title: '修改'
    },
    {
      name: name + 'Cancellation',
      title: '作废'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'Restart',
      title: '重启'
    },
    {
      name: name + 'Detail',
      title: '详情'
    },
    {
      name: name + 'Add',
      title: '新增'
    }
  ],
    productionTask: (name = 'productionTask') => {
      const base = [
    {
      name: name + 'View',
      title: '列表'
    }, {
      name: name + 'Edit',
      title: '编辑'
    },{
      name: name + 'StartTask',
      title: '开始任务'
    },{
      name: name + 'CompleteWork',
      title: '完工'
    }, {
      name: name + 'Restart',
      title: '重启'
    }, {
      name: name + 'BatchAssign',
      title: '批量派工'
    },{
      name: name + 'Assign',
      title: '派工任务'
    }, {
      name: name + 'BatchExport',
      title: '批量导出'
    }, {
      name: name + 'DelayFeedback',
      title: '延期反馈'
    },{
      name: name + 'DelayRecord',
      title: '延期记录'
    }, {
      name: name + 'SetFactorySequenceNumber',
      title: '设置出厂序列号'
    }, {
      name: name + 'PrintCertificate',
      title: '打印合格证'
    }
  ]
  if (sessionStorage.getItem('memberCode') != 'yyhl') {
    base.splice(4, 0,  {
      name: name + 'AllAssign',
      title: '全部派工'
    })
  }

  return base
},
  WorkingHours: name => [
    {
      name: name + 'View',
      title: '列表'
    }, {
      name: name + 'Edit',
      title: '编辑'
    }, {
      name: name + 'Del',
      title: '删除'
    }
  ],
  UpDownWorkRecord: name => [
    {
      name: name + 'View',
      title: '列表'
    }, {
      name: name + 'Export',
      title: '导出'
    }, {
      name: name + 'Edit',
      title: '编辑'
    }, {
      name: name + 'Del',
      title: '删除'
    }
  ],
  PreOrder: name => [
    {
      name: name + 'AddOrder',
      title: '补工单'
    }
  ],
  plansSettings: (name) => [
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'Edit',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'Import',
      title: '导入'
    }
  ],
  performanceSalary: name => [
    {
      name: name + 'View',
      title: '列表'
    }, {
      name: name + 'Export',
      title: '导出'
    }
  ]
}
